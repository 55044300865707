import styled from 'styled-components';

import { Caption } from '@components/styles/Typography';

const ErrorMessage = ({ message }) => <P>{message}</P>;

const P = styled(Caption)`
    color: ${({ theme }) => theme.colors.error};
    margin-top: -10px !important;
    margin-bottom: 5px;
`;
export default ErrorMessage;
