import { useTranslation } from '@hooks';
import { H3, Text3, Subtitle3 } from '@components/styles/Typography';
import {
    AgreementModalWrapper,
    StaticContentArea,
    MarginWrapper,
} from 'components/styles/StaticPagesStyles';

const ClearConsent = () => {
    const { locale } = useTranslation();

    return (
        <AgreementModalWrapper>
            {locale === 'tr' ? (
                <StaticContentArea>
                    <H3>
                        TİCARİ ELEKTRONİK İLETİ / PAZARLAMA VE ANKET VB.
                        AMAÇLARLA KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN AÇIK
                        RIZA METNİ
                    </H3>
                    <MarginWrapper>
                        <Text3>
                            Şirketimiz KEİKEİ TEKSTİL VE GİYİM SANAYİ TİCARET
                            ANONİM ŞİRKETİ (“KEİKEİ”) tarafından Aydınlatma
                            Metni kapsamında ve aşağıda belirtilen amaçlarla
                            sınırlı olarak, bizimle paylaştığınız; iletişim
                            numarası, e-posta adresi, doğum tarihi, cinsiyet, ad
                            soyad, bulunduğunuz ülke bilgisi, alışveriş geçmiş
                            ve alışkanlıklarınıza ilişkin kişisel verilerinizin
                            işlenebilmesi ve belirtilen amaçlarla Aydınlatma
                            Metninde belirtilen alıcı gruplarına aktarılmasına
                            ilişkin açık rızanızı talep etmekteyiz. <br />{' '}
                            <br />
                            <Subtitle3>
                                Açık Rıza Talep Edilen Veri İşleme amaçları:
                            </Subtitle3>
                            <Text3>
                                Genel veya size özel kişiselleştirilmiş
                                bilgilendirmeler, tanıtımlar, kampanyalar,
                                avantajlar, reklamlar, pazarlama faaliyetleri
                                kapsamında güncel iletişim bilgileriniz
                                üzerinden size yönelik ticari iletişim
                                faaliyetlerinde (SMS, e-posta, arama vb.
                                kanallar üzerinden kurulan iletişim)
                                bulunulabilmesi, Ürün ve hizmetlerimizle ilgili
                                müşteri memnuniyetine yönelik anketlerin,
                                kampanya, çekiliş, davet, açılış ve diğer
                                etkinliklere ilişkin davetlerin iletilmesi
                                amaçlarıyla tarafınıza ticari ileti
                                gönderilmesi, Müşteri memnuniyeti hakkında
                                araştırma yapılması Çalıştığımız kurum ve
                                kuruluşların ve diğer bağlı şirketlerimizin
                                hizmet sunumu.
                                <MarginWrapper>
                                    <Subtitle3>
                                        Kişisel verilerimin işlenmesine ilişkin;
                                    </Subtitle3>
                                    <Text3>
                                        Aydınlatma Metnini okuduğumu, kişisel
                                        verilerimin işlenmesine ve aktarılmasına
                                        ilişkin bilgilendirmeyi anladığımı,{' '}
                                        <br /> <br />
                                        Bu kapsamda kişisel verilerimin
                                        işlenmesine itiraz etme hakkımın
                                        bulunduğu bilgisine sahip olduğumu
                                        <br /> <br />
                                        www.keikei.com üzerinden her zaman
                                        ulaşabileceğim aydınlatma metninde
                                        belirtildiği şekilde açık rızama dayalı
                                        olarak işlenen ve/veya aktarılan kişisel
                                        verilerimin işlenmesine ve/veya
                                        aktarılmasına ilişkin vermiş olduğum
                                        açık rızamı dilediğim zaman geri çekme
                                        hak ve yetkim bulunduğu;
                                        <br /> <br />
                                        Kişisel verilerimin işlenmesine ve/veya
                                        aktarılmasına ilişkin olarak 6698 s.
                                        Kanun’un 11. maddesiyle tarafıma tanınan
                                        haklar; son olarak 6698 s. Kanun’un 11.
                                        maddesiyle tarafıma tanınan haklarımı ne
                                        şekilde kullanabileceğim hususlarında
                                        bilgilendirildiğimi,
                                        <br /> <br />
                                        Bu kapsamda; söz konusu kişisel veri
                                        işleme ve aktarma faaliyetlerine bilerek
                                        ve isteyerek rıza gösterdiğimi kabul,
                                        beyan ve taahhüt ederim.
                                    </Text3>
                                </MarginWrapper>
                            </Text3>
                        </Text3>
                    </MarginWrapper>
                </StaticContentArea>
            ) : (
                <StaticContentArea>
                    <H3>
                        COMMERCIAL ELECTRONIC MESSAGE / MARKETING AND SURVEY
                        ETC. EXPRESS CONSENT TO THE PROCESSING OF PERSONAL DATA
                        FOR PURPOSE
                    </H3>
                    <MarginWrapper>
                        <Text3>
                            Within the scope of the Illumination Text by our
                            company KEİKEİTEKSTİL VE
                            GİYİMSANAYİTİCARETANONİMŞİRKETİ(“KEİKEİ”) and
                            limited to the purposes stated below, you have
                            shared with us; We request your express consent
                            regarding the processing of your personal data
                            regarding your contact number, e-mail address, date
                            of birth, gender, name and surname, country of
                            residence, shopping history and habits, and
                            transferring them to the recipient groups specified
                            in the Clarification Text for the specified
                            purposes.{' '}
                            <MarginWrapper>
                                <Subtitle3>
                                    Purposes of Data Processing for which
                                    Explicit Consent is Requested:{' '}
                                </Subtitle3>
                                <Text3>
                                    To be able to carry out commercial
                                    communication activities for you
                                    (communication via channels such as SMS,
                                    e-mail, search, etc.)
                                    <br /> <br />
                                    Sending commercial messages to you for the
                                    purposes of sending surveys regarding
                                    customer satisfaction regarding our products
                                    and services, invitations to campaigns,
                                    sweepstakes, invitations, openings and other
                                    events,
                                    <br /> <br />
                                    Research on customer satisfaction
                                    <br /> <br />
                                    Service provision of the institutions and
                                    organizations we work with and other
                                    affiliated companies.
                                    <br /> <br />
                                    Regarding the processing of my personal
                                    data;
                                    <br /> <br />
                                    I have read the Clarification Text and
                                    understand the information regarding the
                                    processing and transfer of my personal data,
                                    <br /> <br />
                                    In this context, I am informed that I have
                                    the right to object to the processing of my
                                    personal data.
                                    <br /> <br />
                                    I have the right and authority to withdraw
                                    my explicit consent for the processing
                                    and/or transfer of my personal data
                                    processed and/or transferred based on my
                                    explicit consent, as stated in the
                                    clarification text that I can always access
                                    via www.keikei.com; Regarding the processing
                                    and/or transfer of my personal data, p.
                                    6698. Rights granted to me by Article 11 of
                                    the Law; finally 6698 p. I have been
                                    informed about how I can use my rights
                                    granted to me by Article 11 of the Law,
                                    <br /> <br />
                                    In this context; I accept, declare and
                                    undertake that I knowingly and voluntarily
                                    consent to such personal data processing and
                                    transfer activities.
                                </Text3>
                            </MarginWrapper>
                        </Text3>
                    </MarginWrapper>
                </StaticContentArea>
            )}
        </AgreementModalWrapper>
    );
};

export default ClearConsent;
